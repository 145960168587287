export function handleQueryResolve(results, err) {
    if (!!results && results.recordset) {
        return results.recordset
    }
    if (err) {
        console.error(err)
    }

    // return empty array if no results
    return []
}

export function buildMapToField(objToMap) {
    const mapFields = getBasicMap() // Basic field mappers
    if (!objToMap) {
        throw new Error('${objToMap} is undefined')
    }

    return Object.keys(objToMap).reduce((cur, queryField) => {
        try {
            const key = mapFields[queryField]
            const val = objToMap[queryField]
            if (!key) {
                return cur

                // FIXME  We need to map values into one single interface
                //        Under getBasicMap() for different integrations
                // return {
                //   ...cur,
                //   [queryField]: val
                // };
            }

            if (typeof key === 'function') {
                return {
                    ...cur,
                    ...key(val),
                }
            }

            // if its straight up
            return {
                ...cur,
                [key]: val,
            }
        } catch (err) {
            console.error(err, ` >> queryField: ${queryField}`, { mapFields }, { objToMap })
        }
    }, {})
}

export function getBasicMap() {
    return {
        FirstName: 'FIRST NAME',
        LastName: 'LAST NAME',
        FamilyDoc: familyDocName => {
            // when they don't exist
            if (!familyDocName) {
                return {
                    'PRIMARY LAST NAME': '',
                    'PRIMARY FIRST NAME': '',
                }
            }

            const brokenName = familyDocName.split(',')

            // FIXME should extract from base
            return {
                'PRIMARY LAST NAME': brokenName[0].trim(),
                'PRIMARY FIRST NAME': brokenName[1].trim(),
            }
        },
        OHIP: 'HEALTH CARD NUMBER',
        Address: mapFunctions.patientAddress,
        City: 'CITY\/TOWN',
        Province: mapFunctions.defaultProvince,
        Postal: 'POSTAL CODE',
        EMail: 'EMAIL ADDRESS',
        HomePhone: 'TELEPHONE NUMBER',
        MobilePhone: 'CELLPHONE NUMBER',
        Allergies: 'LIST OF ALLERGIES',
        DOB: 'DATE OF BIRTH',
        Gender: 'GENDER',
        Medication: 'Medication',
        PatientID: 'PatientID',
        DoctorID: 'DoctorID',
        DIN: 'DIN',
        Days: 'Days',

        GenericName: 'GenericName',
        BrandName: 'BrandName',
        RxNumber: 'RxNumber',
        RxID: 'RxID',
        RxDate: 'RxDate',
        RxStrength: 'RxStrength',
        RxQtyDispense: 'RxQtyDispense',
        SIGFull: 'SIG',
        RxDays: 'RxDays',
        RxInterval: 'RxInterval',

        InsuranceProvider: 'InsuranceProvider',

        PharmacyAddress: mapFunctions.pharmacyAddress,
        PharmacyCity: 'PHARMACY CITY',
        PharmacyFax: 'PHARMACY FAX NUMBER',
        PharmacyName: 'PHARMACY NAME',
        PharmacyPhone: 'PHARMACY TELEPHONE NUMBER',
        PharmacyPostal: 'PHARMACY POSTAL CODE',
        PharmacyProvince: 'PHARMACY PROVINCE',
        PlanCode: 'PlanCode',
        CarrierID: 'CarrierID',
        PlanID: 'PlanID',
        GroupID: 'GroupID',
        PlanOrder: 'PlanOrder',
        Relationship: 'Relationship',
        PatientCode: 'PatientCode',

        DoctorFirstName: 'PRIMARY FIRST NAME', // additionalDoctors.moreDocs,
        DoctorLastName: 'PRIMARY LAST NAME',
        CPSO: 'CPSO',
        DoctorEmail: 'PRIMARY EMAIL ADDRESS',
        DoctorPhone: 'PRIMARY PHONE #',
        DoctorFax: 'PRIMARY FAX #',
        DoctorAddress: mapFunctions.primaryAddress,
        DoctorCity: 'PRIMARY CITY',
        DoctorProvince: 'PRIMARY PROVINCE',
        DoctorPostal: 'PRIMARY POSTAL CODE',
        RXNumber: 'RXNumber',
        CODE: 'CODE',
        TotalPrice: 'TotalPrice',
        Status: 'Status',
        totalUnits: 'totalUnits',
        totalPatients: 'totalPatients',
        totalDoctors: 'totalDoctors',

        // '': 'PRIMARY EMAIL ADDRESS',
    }
}

// const additionalDoctors = {
//     moreDocs: (i) => {
//         return {
//             'DoctorFirstName': [`PRIMARY FIRST NAME${i}`]
//         }
//     }
// }

// functional mappings that extends
const mapFunctions = {
    defaultProvince: province => ({
        PROVINCE: province || 'ONTARIO',
    }),
    patientAddress: address => {
        const breakdown = parseAddress(address)

        return {
            'UNIT NUMBER': breakdown.unitNumber,
            'STREET NUMBER': breakdown.streetNumber,
            'STREET NAME': breakdown.streetName,
        }
    },
    pharmacyAddress: address => {
        const breakdown = parseAddress(address)

        return {
            'PHARMACY UNIT NUMBER': breakdown.unitNumber,
            'PHARMACY STREET NUMBER': breakdown.streetNumber,
            'PHARMACY STREET NAME': breakdown.streetName,
        }
    },
    primaryAddress: address => {
        const breakdown = parseAddress(address)

        return {
            'PRIMARY UNIT NUMBER': breakdown.unitNumber,
            'PRIMARY STREET NUMBER': breakdown.streetNumber,
            'PRIMARY STREET NAME': breakdown.streetName,
        }
    },
}

function parseAddress(addressStr) {
    let unitNumber = ''
    let streetNumber = ''
    let streetName = ''
    const streetBreakdown = addressStr.match(/[A-za-z]+[\s|A-za-z]+/g)
    if (streetBreakdown && streetBreakdown.length) {
        streetName = streetBreakdown[0].trim()
        streetNumber = addressStr.replace(streetName, '').trim()
        if (streetNumber.indexOf('-') != -1) {
            const numbersBreakdown = streetNumber.split('-')
            unitNumber = numbersBreakdown[0].trim()
            streetNumber = numbersBreakdown[1].trim()
        }
    }

    return {
        unitNumber,
        streetNumber,
        streetName,
    }
}