import { handleQueryResolve } from '../utils'

export default function () {
  const attributes = []

  // if(name){
  //     attributes.push({
  //         param: 'name',
  //         type: 'sql.VarChar(50)',
  //         value: '%' + name + '%'
  //     })
  // }
  return this.query(`
    SELECT
        Patient.PatientID,
        Patient.LastName AS LastName,
        Patient.FirstName AS FirstName,
        SUBSTRING(HomePhone,1,3)+'-'+SUBSTRING(HomePhone,4,3)+'-'+SUBSTRING(HomePhone,7,4) AS PhoneNumber
    FROM
        Fillware.dbo.Patient
    INNER JOIN Fillware.dbo.Rx ON Fillware.dbo.Patient.PatientID = Fillware.dbo.Rx.PatientID
    WHERE
        Rx.RxDate BETWEEN DATEADD(month,-3,GetDate()) And GetDate()
    AND
        Fillware.dbo.Patient.PatientID NOT IN (
            SELECT DISTINCT
                Rx.PatientID
            FROM fillware.dbo.Rx
            WHERE Rx.RxDate BETWEEN DATEADD(year,-1,GetDate()) And GetDate()
            AND Rx.DIN IN (93899979, 93899988, 93899987)
        )
    GROUP BY
        Fillware.dbo.Patient.PatientID,
        LastName,
        FirstName,
        HomePhone
    HAVING count(Rx.DIN) > 2
    ORDER BY LastName, FirstName;
    `).then(handleQueryResolve)
}
